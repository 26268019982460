import {Client, StompSubscription, Frame, StompHeaders, IFrame} from '@stomp/stompjs';
import { Observable } from 'rxjs';
import * as Rx from 'rxjs';

import { config } from '../config';
import { SsoToken } from '../../authentication/models/login'
import { SaveSettingsRequest } from '../models/application';
import { SendLogToServer } from '../../shared/logger/actions/logger';
import { heartbeatReceived, clearFailedMessages } from '../../authentication/actions/connection';
import connectionStore from '../../authentication/store/connection';
import { UpdateTokenRequest } from '../../shared/notifications/models/notification';
import { v1 } from 'uuid';
import { getSSOAccessToken } from '../../authentication/selectors/connection';

export class LoginResponse {
  jwt: string;
}

export class LoginRequest {
  username: string;
  password: string;
}

export let StompClient: Client = new Client({
  brokerURL: config.websocketsURL + '/ows/websocket',
  reconnectDelay: 0,
  // debug: str => console.log(new Date(), str) // UNCOMMENT THIS LINE FOR STOMP DEBUG MESSAGES
});

const WEBSOCKET_READY_STATE = 1;

export class StompService {

  public client: Client;
  public disconnecting = false;

  constructor(client: Client) {
    this.client = client
  }

  public connect(token: SsoToken): Observable<Frame | Error> {
    var wsObservable: Observable<Frame | Error> = new Rx.Observable(
      (observer: Rx.Observer<any>) => {
        const accessToken = token
        const headers: StompHeaders = {
          'permessage-deflate': 'true',
          'Access-Token': accessToken.token
        }

        this.client.connectHeaders = headers
        this.client.activate()

        this.client.onConnect = (frame: Frame) => {
          StompClient = this.client
          if (this.client.webSocket) {
            observer.next(accessToken);

            const messages = connectionStore.getState().messageQueue
            connectionStore.dispatch(clearFailedMessages())
            for (let i = 0; i < messages.length; i++) {
              const args = messages[i]
              this.sendMessage(args[0], args[1], args[2], args[3])
            }

            return observer;
          } else {
            return observer.error(new Error('error.Connection error'))
          }
        }

        this.client.onStompError = (error: IFrame) => {
          this.disconnecting = false;
          if (error?.headers?.message === 'Token expired') {
            observer.error(new Error('error.Token expired'))
          } else {
            observer.error(new Error('error.Connection failed.'))
          }
        }

        this.client.onWebSocketClose = () => {
          // correctly closed
        }

        this.client.onWebSocketError = (event) => {
          this.disconnecting = false;
          observer.error(new Error('error.Connection failed.'))
        }

        this.client.onDisconnect = () => {
          this.disconnecting = false
          observer.complete()
        }
      })
      return wsObservable;
  }

  public sendHeartbeat() {
    if (this.client && this.client.webSocket) {
      return Rx.of(this.sendMessage('/app/im/heartbeat', JSON.stringify({correlationId: v1()})));
    }
    return Rx.of(false);
  }

  public receiveHeartbeats() {
    return this.subscribe('/user/topic/im/heartbeat');
  }

  public receiveHeartbeatSettings() {
    return this.subscribe('/user/topic/im/heartbeat/settings');
  }

  sendTokenLogin(): any {
    this.sendMessage('/app/login/token', JSON.stringify({}));
  }

  sendConfirmation(confirm: boolean) {
    this.sendMessage('/app/login/takeover', JSON.stringify({confirm}));
  }

  public receiveSettings() {
    return this.subscribe('/user/topic/settings');
  }

  public receiveUserPermissions() {
    return this.subscribe('/user/topic/im/userpermissions');
  }

  public receiveConnectionMessages() {
    return this.subscribe('/user/topic/im/connection');
  }

  public receiveUnspecifiedMessages() {
    return this.subscribe('/user/topic/im');
  }

  public httpLogout() {
    return fetch(config.serverURL + '/auth/logout',
    {
      mode: 'cors',
      method: 'GET',
      credentials: 'include'
    });
  }

  public sendLogout() {

    this.sendMessage('/app/logout', JSON.stringify({}));

    if (this.checkWSConnection()) {
      this.disconnecting = true;
      this.client.deactivate();
    }

  }

  public authorize() {
    const accessToken = getSSOAccessToken(connectionStore.getState());
    fetch(config.serverURL + '/auth/authorize', {
      mode: 'cors',
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken
      }
    });
  }

  public disconnect() {
    if (this.checkWSConnection()) {
      this.client.deactivate();
      this.disconnecting = true;
    }
  }

  public saveSettings(settings: SaveSettingsRequest) {
    this.sendMessage('/app/settings/save', JSON.stringify(settings));
  }

  public updateNotificationToken(request: UpdateTokenRequest) {
    this.sendMessage('/app/notifications/token', JSON.stringify(request));
  }

  public sendClientLogToServer(log: SendLogToServer) {
    this.sendMessage(
      '/app/logging',
      JSON.stringify({
        message: log.payload.message,
        logLevel: log.payload.level.toString(),
        date: log.payload.time.toLocaleString(),
        id: log.payload.id
      }
      ),
      {},
      true
    );
  }

  public subscribe(destination: string, afterSubscribe?: () => void): Observable<any> {
    return new Rx.Observable((observer: Rx.Observer<any>) => {
      let subscribe: StompSubscription;
      const accessToken = getSSOAccessToken(connectionStore.getState());
      if (this.checkWSConnection()) {
        subscribe = this.client.subscribe(destination, (frame: Frame) => {
          const body = JSON.parse(frame.body);
          connectionStore.dispatch(heartbeatReceived({}));
          observer.next(body);

        }, { id: destination, 'Access-Token': accessToken });
        if (afterSubscribe) {
          afterSubscribe();
        }
      } else {
        console.log('**** Could not subscribe over WS to: ', destination);
      }

      return () => {
        if (!!subscribe) {
          subscribe.unsubscribe();
        }
      };
    });
  }

  /**
   * Send ws message
   * @param destination server stomp destination
   * @param message json message
   * @param headers
   * @param enqueueFailed save message to queue if ws is not available (sending failed) for later re-send
   */
  public sendMessage(destination: string, message: string, headers: any = {}, enqueueFailed: boolean = false) {
    if (this.checkWSConnection()) {

      const accessToken = getSSOAccessToken(connectionStore.getState());
      try {
        this.client.publish({
          destination: destination,
          body: message,
          headers: {...headers, 'Access-Token': accessToken}
        });
        return true;
      } catch (e) {
        return false;
      }
    } else {
      console.log(
        'Websocket Connection is not stable! Client exists? : ', this.client
      );
      console.log(' Websocket exists? : ' + this.client.webSocket);
      console.log(' Connection exists? : ' + this.client.connected);
      // console.log(' Connection is ready? : ' + this.client.ws.readyState === this.client.ws.OPEN); */

      if (enqueueFailed) {
       // store.dispatch(enqueueFailedMessage(arguments));
      }
      console.log(
        '**** Could not send :' + message + ' over WS to destination: ',
        destination
      );
      return false;
    }
  }

  private checkWSConnection(): boolean {
    return (
      this.client &&
      this.client.webSocket &&
      this.client.connected &&
      this.client.webSocket.readyState === WEBSOCKET_READY_STATE
    );
  }
}
