import React, { useState } from 'react';
import { useDispatch } from 'react-redux'
import { I18n } from 'react-redux-i18n';
import NotificationsConfigModalComponent from 'js/shared/notifications/components/NotificationsConfigModal';
import withAutoClose from 'js/shared/autoclose/components/Autoclose';
import BulkOrderUploadModal from 'js/bulkOrders/components/BulkOrderUploadModal';
import { isBasketOrderUploadEnabled } from 'js/requests/selectors/requests';
import VersionInfoModal from 'js/shared/notifications/components/VersionInfoModal';
import PasswordChangeModal from 'js/shared/passwordChange/components/PasswordChangeModal';
import { MemoTranslate } from 'js/shared/i18n/components/memoTranslate';
import { config } from 'js/main/config';
import store from 'js/main/store/store';
import MappedInfoModal from 'js/shared/notifications/components/MappedInfoModal';
import { AdminPortal } from '@frontegg/react';
import useClickOutside from 'js/hooks/useOnClickOutside';
import { hasPermissionForVenue } from '../../../authentication/selectors/connection'
import { triggerDownloadCsv } from '../../ui/actions/table'
import { ComponentType } from '../../ui/models/component'

const SidebarContextMenu = ({ bulkOrderUploadEnabled, passwordExpired }) => {
  const clickRef = React.useRef();
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [bulkOrderUploadModalOpen, setBulkOrderUploadModalOpen] = useState(false);
  const [versionInfoModalOpen, setVersionInfoModalOpen] = useState(false);
  const [passwordChangeModalOpen, setPasswordChangeModalOpen] = useState(passwordExpired);
  const [mappedInstrumentsModalOpen, setMappedInstrumentsModalOpen] = useState(false);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const dispatch = useDispatch();
  const liveDataPortalMode = config.liveDataPortalMode;

  const handleBulkOrderUploadModalOpen = () => setBulkOrderUploadModalOpen(true);
  const handleMappedInstrumentsModalOpen = () => setMappedInstrumentsModalOpen(true);
  const handlePasswordChangeModalOpen = () => setPasswordChangeModalOpen(true);
  const handleAdminPortalOpen = () => AdminPortal.show();
  const handleVersionInfoModalOpen = () => setVersionInfoModalOpen(true);

  const onClickOutside = () => {
    setShowContextMenu(false);
  }

  const downloadSettlementPrices = (e) => {
    dispatch(triggerDownloadCsv([
      {name: 'contract', title: I18n.t("columns.market.contract")},
      {name: 'price', title: I18n.t("columns.market.stlprc")},
      {name: 'counterparty', title: I18n.t("columns.market.stlcpy")}
    ], [], [], ComponentType.SettlementPrices, "", [], {}))
  }

  useClickOutside(clickRef, onClickOutside);

  return (
    <>
      <div ref={clickRef}>
        <i className={`oi oi-ellipses white`} title={I18n.t('sidebar.more')} onClick={() => setShowContextMenu(!showContextMenu)} />
        <div className="sidebar-context-menu" style={{ display: showContextMenu ? 'block' : 'none' }}>
          <ul>
            {bulkOrderUploadEnabled && (
              <li onClick={handleBulkOrderUploadModalOpen}>
                <MemoTranslate value="sidebar.bulkOrderUpload" />
              </li>
            )}
            {!liveDataPortalMode && (
              <li onClick={handleMappedInstrumentsModalOpen}>
                <MemoTranslate value="sidebar.mappedInstruments" />
              </li>
            )}
              <li onClick={handleAdminPortalOpen}>
                  <MemoTranslate value="sidebar.adminPortal" />
              </li>
            {hasPermissionForVenue(store.getState(), 'eurex') &&
            <li onClick={downloadSettlementPrices}>
              <MemoTranslate value="sidebar.settlementPrices" />
            </li>}
            <li onClick={handleVersionInfoModalOpen}>
              <MemoTranslate value="sidebar.versionInfo" />
            </li>
          </ul>
        </div>
      </div>

      {notificationModalOpen && (
        <NotificationsConfigModalComponent
          closeModal={() => setNotificationModalOpen(false)}
          modalVisible={notificationModalOpen}
        />
      )}
      {versionInfoModalOpen && (
        <VersionInfoModal
          closeModal={() => setVersionInfoModalOpen(false)}
          modalVisible={versionInfoModalOpen}
        />
      )}
      {mappedInstrumentsModalOpen && (
        <MappedInfoModal
          closeModal={() => setMappedInstrumentsModalOpen(false)}
          modalVisible={mappedInstrumentsModalOpen}
        />
      )}
      {isBasketOrderUploadEnabled(store.getState()) && bulkOrderUploadModalOpen && (
        <BulkOrderUploadModal
          closeModal={() => setBulkOrderUploadModalOpen(false)}
          modalVisible={bulkOrderUploadModalOpen}
        />
      )}
    </>
  );
};

export default withAutoClose(SidebarContextMenu);
