import * as React from 'react';

import LoginFormData from '../models/login';
import {
  Consumer as ThemeConsumer,
  Provider as ThemeProvider
} from '../../shared/context/themeContext';
import history, {historyReplace} from '../../main/history';
import { I18n, Translate } from 'react-redux-i18n';
import { config } from '../../main/config';
import store from '../../main/store/store';
import { delayLogin } from '../actions/authentication';

interface Props {
  isAuthorized: boolean;
  isLoginPending: boolean;
  error: any;
  handleSubmit: (form: LoginFormData) => void;
  loginError: any;
  pendingLogin: (message: string) => void;
  reactivateSubmitButtonAfterTimeout: (message: string) => void;
  timeUntilNextLoginAttempt: number;
  takeoverConfirm: (payload: boolean) => void;
  showTakeoverConfirm: boolean;
}

interface State extends LoginFormData {
  userNameIsFocused: boolean;
  passwordIsFocused: boolean;
  themeContextValue: {
    selectedTheme: string;
    setTheme: any;
  };
  isButtonTimeoutRunning: boolean;
  currentLoginDelay: number;
  takeoverWaiting: boolean;
}

export function NotificationMessage(props: any): JSX.Element | null {
  if (!props.message) {
    return null;
  }
  // message after server disconnect is not translated
  let message = props.message;
  if (!message) {
    message = props.message.replace(/\.$/, '');
    message = message.substring(message.lastIndexOf('.') + 1);
  }
  let level = props.level ? props.level : 'danger';
  return (
    <div className={'alert alert-' + level} role="alert" data-test="login-notification-message">
      {message}
    </div>
  );
}

export default class LoginForm extends React.Component<Props, State> {
  private usernameInput: any;
  private passwordInput: any;
  private timeouts: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      token: '',
      profile: I18n.t('login.profile', { number: 1 }),
      userNameIsFocused: false,
      passwordIsFocused: false,
      themeContextValue: {
        selectedTheme: 'theme-estar',
        setTheme: this.setTheme
      },
      isButtonTimeoutRunning: false,
      currentLoginDelay: props.timeUntilNextLoginAttempt,
      takeoverWaiting: false
    };

    this.timeouts = [];

    this.usernameInput = React.createRef();
    this.passwordInput = React.createRef();
    this.isPendingLogin = this.isPendingLogin.bind(this);

    this.confirmTakeover = this.confirmTakeover.bind(this);
  }

  componentDidMount() {
    if (this.props.isAuthorized) {
      window.history.replaceState({},null,'/');
      history.replace('/');
    }
  }

  componentDidUpdate() {
    if (this.props.isAuthorized) {
      historyReplace('/');
    }
  }


  setTheme(theme: string) {
    this.setState({
      themeContextValue: {
        ...this.state.themeContextValue,
        selectedTheme: theme
      }
    });
  }

  isPendingLogin() {
    let loginPending = this.props.isLoginPending;
    return loginPending;
  }

  confirmTakeover(confirm: boolean) {
    this.props.takeoverConfirm(confirm);
    if (confirm) {
      this.setState((prevState) => {
        return {
          ...prevState,
          takeoverWaiting: true
        }
      });
    }
  }

  render() {
    const form = this.state;
    const logoName = config.branding.logo;
    const logo = `${config.subfolder}/logo/${logoName}`;
    if (!config.complete) {
      return (
          <div className="login-container row justify-content-center h-100">
            <div className="login-form center-block" >
              {/* if the server is not reachable, there are no translations available - display plain string */}
              <div className="login-error">Trying to contact EWT server.</div>
          </div>
        </div>
      );
    }
    const takeoverConfirm = (
      <div className="login-error">
        <Translate value={this.state.takeoverWaiting ? 'login.takeOverWaiting' : 'login.takeOverConfirm' } /> 
        <div hidden={this.state.takeoverWaiting} className="btn-group">
            <button data-test="takeover-session-confirm" className="btn btn-approval w150 ml-auto" onClick={e => this.confirmTakeover(true)}>OK</button><button className="btn btn-secondary w150 ml-auto" onClick={e => this.props.takeoverConfirm(false)}>Cancel</button>
        </div>
      </div>);

    return (
      <ThemeProvider value={this.state.themeContextValue}>
        <ThemeConsumer>
          {({ selectedTheme }) => (
            <div className={selectedTheme + ' h-100'}>
             <div className="login-container row justify-content-center h-100">
                <div className="login-form center-block">
                  { (this.props.showTakeoverConfirm) ?
                    takeoverConfirm : (<><Translate value="login.ssoLogin" /><div className="spinner-login"><div className="spinner-login spinner-border text-secondary" role="status">
                      <span className="sr-only"></span>
                    </div></div></>)
                  }

                </div>
              </div>
            </div>
          )}
        </ThemeConsumer>
      </ThemeProvider>
    );
  }

  componentWillUnmount() {
    this.timeouts.map((t: any) => clearTimeout(t));
  }
}
