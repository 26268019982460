import { ErrorResponse, HeartbeatResponse, HeartbeatSettingsResponse } from '../../main/models/application';
import { LogAction } from '../../main/middlewares/logger';
import { LogLevel } from '../../shared/logger/models/logger';
import { I18n } from 'react-redux-i18n';
import LoginFormData, { SsoToken } from '../models/login'

export enum ActionTypes {
  CONNECTION_START      = '[Connection] CONNECTION_START',
  SSO_CONNECTION_START  = '[Connection] SSO CONNECTION START',
  CONNECTION_SUCCESS    = '[Connection] CONNECTION_SUCCESS',
  CONNECTION_FAILED    = '[Connection] CONNECTION_FAILED',
  RECONNECTION_SUCCESS  = '[Connection] RECONNECTION_SUCCESS',
  CONNECTION_LOST       = '[Connection] CONNECTION_LOST',
  DISCONNECT            = '[Disconnect] DISCONNECT',
  HEARTBEAT             = '[Connection] HEARTBEAT',
  TAKEOVER_SHOW             = '[Connection] TAKEOVER_SHOW',
  TAKEOVER_CONFIRM             = '[Connection] TAKEOVER_CONFIRM',
  APPLICATION_ERROR     = '[Application] ERROR',
  APPLICATION_INIT      = '[Application] INIT',
  APPLICATION_START     = '[Application] START',
  APPLICATION_COMPLETE  = '[Application] COMPLETE',
  HEARTBEAT_RECEIVED    = '[Application] HEARTBEAT RECEIVED',
  HEARTBEAT_SETTINGS    = '[Application] HEARTBEAT SETTINGS',
  CLOSE_CLEAN           = '[Application] CLOSE AND CLEAN',
  CONNECTION_PROBLEM    = '[Application] CONNECTION PROBLEM',
  CONNECTED_AFTER_PROBLEM    = '[Application] CONNECTED AFTER A PROBLEM',
  CLEANED                = '[Application] CLEANED',
  ENQUEUE_FAILED_MESSAGE  = '[Application] Enqueue failed message',
  CLEAR_FAILED_MESSAGES  = '[Application] Clear failed messages',
  SSO_EXPIRATION      = '[Connection] Access token expiration time',
  SSO_SAVE_ACCESS_TOKEN = '[Connection] Save access token'
}

export interface ConnectionStartAction {
  type: ActionTypes.CONNECTION_START;
  payload: LoginFormData;
}

export interface SSOConnectionStartAction {
  type: ActionTypes.SSO_CONNECTION_START;
  payload: SsoToken;
}

export interface ConnectionSuccessAction {
  type: ActionTypes.CONNECTION_SUCCESS;
}

export interface ReconnectionSuccessAction {
  type: ActionTypes.RECONNECTION_SUCCESS;
}

export interface ConnectionLostAction extends LogAction {
  type: ActionTypes.CONNECTION_LOST;
  error: any;
}

export interface ConnectionFailedAction extends LogAction {
  type: ActionTypes.CONNECTION_FAILED;
  error: any;
}

export interface DisconnectAction extends LogAction {
  type: ActionTypes.DISCONNECT;
}

export interface ErrorAction extends LogAction {
  type: ActionTypes.APPLICATION_ERROR;
  payload: ErrorResponse;
}

export interface HeartbeatAction {
  type: ActionTypes.HEARTBEAT;
}

export interface TakeoverShowAction {
  type: ActionTypes.TAKEOVER_SHOW;
  payload: boolean;
}

export interface TakeoverConfirmAction {
  type: ActionTypes.TAKEOVER_CONFIRM;
  payload: boolean;
}

export interface ApplicationInitAction {
  type: ActionTypes.APPLICATION_INIT;
}

export interface ApplicationCompleteAction {
  type: ActionTypes.APPLICATION_COMPLETE;
}

export interface ApplicationStartAction {
  type: ActionTypes.APPLICATION_START;
}

export interface HeartbeatReceivedAction {
  type: ActionTypes.HEARTBEAT_RECEIVED;
  payload: HeartbeatResponse;
}

export interface HeartbeatSettingsAction {
  type: ActionTypes.HEARTBEAT_SETTINGS;
  payload: HeartbeatSettingsResponse;
}

export interface CloseAndCleanAction {
  type: ActionTypes.CLOSE_CLEAN;
}

export interface ConnectionProblemAction extends LogAction {
  type: ActionTypes.CONNECTION_PROBLEM;
  error: string;
}

export interface ConnectedAfterProblemAction {
  type: ActionTypes.CONNECTED_AFTER_PROBLEM;
}

export interface CleanPerformedAction {
  type: ActionTypes.CLEANED;
}

export interface EnqueueFailedMessageAction {
  type: ActionTypes.ENQUEUE_FAILED_MESSAGE;
  payload: any;
}

export interface ClearFailedMessagesAction {
  type: ActionTypes.CLEAR_FAILED_MESSAGES;
}

export interface SsoExpirationAction {
  type: ActionTypes.SSO_EXPIRATION;
  payload: {expiration: number;};
}

export interface SsoSaveAccessToken {
  type: ActionTypes.SSO_SAVE_ACCESS_TOKEN;
  payload: string;
}

export function connectionStart(data: LoginFormData): ConnectionStartAction {
  return {
    type: ActionTypes.CONNECTION_START,
    payload: data
  };
}

export function ssoConnectionStart(token: SsoToken): SSOConnectionStartAction {
  return {
    type: ActionTypes.SSO_CONNECTION_START,
    payload: token
  };
}

export function connectionSuccess(): ConnectionSuccessAction {
  return {
    type: ActionTypes.CONNECTION_SUCCESS,
  };
}

export function reconnectionSuccess(): ReconnectionSuccessAction {
  return {
    type: ActionTypes.RECONNECTION_SUCCESS
  };
}

export function connectionLost(error: any): ConnectionLostAction {
  return {
    type: ActionTypes.CONNECTION_LOST,
    error: error,
    logLevel: LogLevel.ERROR,
    logMessage: I18n.t('error.Connection lost')
  };
}

export function connectionFailed(error: any): ConnectionFailedAction {
  return {
    type: ActionTypes.CONNECTION_FAILED,
    error: error,
    logLevel: LogLevel.ERROR,
    logMessage: I18n.t('error.Connection lost')
  };
}

export function connectionProblem(error: any): ConnectionProblemAction {
  return {
    type: ActionTypes.CONNECTION_PROBLEM,
    error: error,
    logLevel: LogLevel.WARN,
    logMessage: I18n.t('error.Connection issues occurred')
  };
}

export function connectedAfterProblem(): ConnectedAfterProblemAction {
  return {
    type: ActionTypes.CONNECTED_AFTER_PROBLEM
  };
}

export function disconnect(): DisconnectAction {
  return {
    type: ActionTypes.DISCONNECT,
    logLevel: LogLevel.ERROR,
    logMessage: I18n.t('error.Disconnect')
  };
}

export function heartbeatAction(): HeartbeatAction {
  return {
    type: ActionTypes.HEARTBEAT
  };
}

export function takeoverShow(payload: boolean): TakeoverShowAction {
  return {
    type: ActionTypes.TAKEOVER_SHOW,
    payload
  };
}

export function takeoverConfirm(payload: boolean): TakeoverConfirmAction {
  return {
    type: ActionTypes.TAKEOVER_CONFIRM,
    payload
  };
}

export function applicationError(content: ErrorResponse): ErrorAction {
  return {
    type: ActionTypes.APPLICATION_ERROR,
    payload: content,
    logLevel: LogLevel.ERROR,
    logMessage: I18n.t('error.Error occured')
  };
}

export function applicationInit(): ApplicationInitAction {
  return {
    type: ActionTypes.APPLICATION_INIT
  };
}

export function applicationComplete(): ApplicationCompleteAction {
  return {
    type: ActionTypes.APPLICATION_COMPLETE
  };
}

export function applicationStarted(): ApplicationStartAction {
  return {
    type: ActionTypes.APPLICATION_START
  };
}

export function heartbeatReceived(content: HeartbeatResponse): HeartbeatReceivedAction {
  return {
    type: ActionTypes.HEARTBEAT_RECEIVED,
    payload: content
  };
}

export function heartbeatSettingsLoadSuccess(content: HeartbeatSettingsResponse): HeartbeatSettingsAction {
  return {
    type: ActionTypes.HEARTBEAT_SETTINGS,
    payload: content
  };
}

export function closeAndClean(): CloseAndCleanAction {
  return {
    type: ActionTypes.CLOSE_CLEAN
  };
}

export function cleaned(): CleanPerformedAction {
  return {
    type: ActionTypes.CLEANED
  };
}

export function enqueueFailedMessage(message: any): EnqueueFailedMessageAction {
  return {
    type: ActionTypes.ENQUEUE_FAILED_MESSAGE,
    payload: message
  };
}

export function clearFailedMessages(): ClearFailedMessagesAction {
  return {
    type: ActionTypes.CLEAR_FAILED_MESSAGES
  };
}

export function ssoExpiration(timestamp: number): SsoExpirationAction {
  return {
    type: ActionTypes.SSO_EXPIRATION,
    payload: {expiration: timestamp}
  };
}

export function ssoSaveAccessToken(accessToken: string): SsoSaveAccessToken {
  return {
    type: ActionTypes.SSO_SAVE_ACCESS_TOKEN,
    payload: accessToken
  };
}

export type Action = ConnectionStartAction | 
                     SSOConnectionStartAction |
                     ConnectionSuccessAction |
                     ConnectionLostAction |
                     ConnectionFailedAction |
                     DisconnectAction |
                     HeartbeatAction |
                     TakeoverShowAction |
                     TakeoverConfirmAction |
                     ErrorAction |
                     ApplicationInitAction |
                     ApplicationStartAction |
                     ApplicationCompleteAction |
                     HeartbeatReceivedAction |
                     HeartbeatSettingsAction |
                     ReconnectionSuccessAction |
                     CloseAndCleanAction |
                     ConnectionProblemAction |
                     ConnectedAfterProblemAction |
                     CleanPerformedAction |
                     EnqueueFailedMessageAction |
                     ClearFailedMessagesAction |
                     SsoExpirationAction |
                     SsoSaveAccessToken;
