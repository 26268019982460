import { ActionsObservable, combineEpics } from 'redux-observable';
import orderBookStore from '../../orderbook/store/orderbooks'
import {
  ActionTypes as Connection, closeAndClean,
  connectionLost,
  connectionSuccess, disconnect,
  ssoConnectionStart,
} from '../actions/connection'
import {loginSuccess, loginFailure, ActionTypes as Authentication,} from '../actions/authentication'
import * as Rx from 'rxjs';

import { StompService, StompClient } from '../../main/services/stompService';
import {getSSOAccessToken} from '../selectors/connection';
import { filter, map, catchError, switchMap, mergeMap, takeUntil } from 'rxjs/operators'
import connectionStore from '../store/connection';
import store from "../../main/store/store";

const stompService = new StompService(StompClient);

export const ssoConnection: any = (
  actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === Connection.SSO_CONNECTION_START),
    map(action => action.payload),
    mergeMap((data: any) => {
        if (stompService.disconnecting) {
          // retry until stompService is fully disconnected since last logout
          return new Rx.Observable((observer: any) => {
            setTimeout(
              () => observer.next(ssoConnectionStart(data)), 1000
            )
          }).pipe(map((action) => {
            return action
          }))
        }
        return stompService.connect(data).pipe(
          map((frame: any) => {
            if (frame.token) {
              stompService.authorize();
              return connectionSuccess()
            }
            return Rx.EMPTY
          }),
          catchError((error: any) => {
              return Rx.of(connectionLost(error.message || 'Internal server error occurs.'))
          })
        );
      }
    )
  );
};

export const logoutEpic: any = (
  actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === Connection.CLOSE_CLEAN
    ),
    switchMap(() => {
        store.dispatch(disconnect());
      //stompService.httpLogout()
      //stompService.sendLogout()
      orderBookStore.dispatch(closeAndClean())
      return Rx.of();
    })
  );
};

export const ssoLoginSubscribe: any = (
  actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === Connection.CONNECTION_SUCCESS),
    switchMap(() => {
      return stompService.subscribe(
        '/user/topic/im/login',
        () => stompService.sendMessage('/app/tokenlogin',
          JSON.stringify({accessToken: getSSOAccessToken(connectionStore.getState())})
        )
      ).pipe(
        map((content: any) => {
          if (content.connected && content.userPermissions !== null) {
            return loginSuccess(content)
          }
          return loginFailure(content)
        }),
        catchError(error => {
          return Rx.of(loginFailure(error))
        })
      );

      return Rx.EMPTY
    })
  );
};

export const ssoConnectionEpic = combineEpics(
  logoutEpic,
  ssoConnection,
  ssoLoginSubscribe,
);
